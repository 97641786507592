<template>
  <a-modal
    :confirm-loading="!(!loading && !confirmLoading)"
    title="Редактировать заявку"
    class="claim-edit-modal"
    :visible="modalVisible"
    :closable="true"
    @cancel="resetForm"
    @ok="update"
  >
    <a-form :model="claim">
      <div class="row">
        <div class="col-md-6">
          <a-upload
            name="logo"
            list-type="picture-card"
            class="logo-uploader"
            :show-upload-list="false"
            :headers="getHeaders()"
            :action="getUrl"
            :before-upload="beforeUpload"
            @change="uploadImage"
          >
            <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
            <div v-else>
              <loading-outlined v-if="loading"></loading-outlined>
              <PlusOutlined v-else></PlusOutlined>
              <div class="ant-upload-text">Логотип</div>
            </div>
          </a-upload>
          <div class="mb-4 text-center" v-if="imageUrl">
            <a-button @click="removeImage" type="link" danger><CloseCircleOutlined />Удалить логотип</a-button>
          </div>
        </div>
        <div class="col-md-6">
          <a-upload
            name="background"
            list-type="picture-card"
            class="logo-uploader"
            :show-upload-list="false"
            :headers="getHeaders()"
            :action="getUrl"
            :before-upload="beforeUpload"
            @change="uploadBackground"
          >
            <img v-if="backgroundUrl" :src="backgroundUrl" alt="avatar" />
            <div v-else>
              <loading-outlined v-if="loading"></loading-outlined>
              <PlusOutlined v-else></PlusOutlined>
              <div class="ant-upload-text">Фон</div>
            </div>
          </a-upload>
          <div class="mb-4 text-center" v-if="backgroundUrl">
            <a-button @click="removeBackground" type="link" danger><CloseCircleOutlined />Удалить фон</a-button>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <a-form-item label="Название бренда">
          <a-input
            ref="claimName"
            placeholder="Введите название бренда"
            v-model:value="claim.name"
          />
        </a-form-item>
        <a-form-item label="ИНН/ПИНФЛ">
          <a-input
            placeholder="Введите ИНН или ПИНФЛ"
            v-model:value="claim.tin"
          />
        </a-form-item>
        <a-form-item label="МФО">
          <a-input
            placeholder="Введите МФО"
            v-model:value="claim.mfo"
          />
        </a-form-item>
        <a-form-item label="Расчетный счет">
          <a-input
            placeholder="Введите расчетный счет"
            v-model:value="claim.account_no"
          />
        </a-form-item>
        <a-form-item name="pos_type" label="Тип кассовой системы">
          <a-select
            v-model:value="claim.pos_type"
            placeholder="Выберите тип"
          >
            <a-select-option key="1" value="1">JOWI</a-select-option>
            <a-select-option key="2" value="2">R-Keeper</a-select-option>
            <a-select-option key="3" value="3">IIKO</a-select-option>
            <a-select-option key="5" value="5">Poster</a-select-option>
            <a-select-option key="3" value="13">AliPOS</a-select-option>
            <a-select-option key="16" value="16">Телеметрон</a-select-option>
            <a-select-option key="null" value="null">отсутствует</a-select-option>
            <a-select-option key="10" value="10">другая</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item name="wallet_type" label="Тип кошелька для чаевых">
          <a-select
            v-model:value="claim.wallet_type"
            placeholder="Тип кошелька для чаевых"
          >
            <a-select-option key="personal" value="personal">Личный</a-select-option>
            <a-select-option key="shared" value="shared">Общий</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Коммиссия">
          <a-input
            placeholder="Введите коммиссию"
            v-model:value="claim.commission"
          />
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {
  PlusOutlined,
  LoadingOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons-vue';
import {ref, reactive, toRaw, onMounted, nextTick} from 'vue';
import { getHeaders } from "@/helpers/header"
import { getBase64 } from "@/helpers/base64";
import { message } from "ant-design-vue";
import apiClient from "@/services/axios";
import Claim from "@/models/Claim";

const props = defineProps({
  claimId: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(['hide-modal', 'reload']);

let imageUrl = ref(''),
  backgroundUrl = ref(''),
  loading = ref(true),
  confirmLoading = ref(false),
  modalVisible = ref(true),
  claimName = ref("claimName"),
  initialClaim = new Claim({}),
  claim = reactive(initialClaim);

const
  uploadImage = info => {
    imageUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      claim.logo = info.file.response.data
      getBase64(info.file.originFileObj, base64Url => {
        imageUrl.value = base64Url;
        loading.value = false;
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  uploadBackground = info => {
    backgroundUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      claim.background = info.file.response.data
      getBase64(info.file.originFileObj, base64Url => {
        backgroundUrl.value = base64Url;
        loading.value = false;
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  removeImage = () => {
    imageUrl.value = ''
    claim.logo = null

    confirmLoading.value = true;
    apiClient.put(`/claim/update?claim=${props.claimId}`, { logo: null })
    .then(() => {
      // console.log(response)
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      confirmLoading.value = false;
    })
  },
  removeBackground = () => {
    backgroundUrl.value = ''
    claim.background = null

    confirmLoading.value = true;
    apiClient.put(`/claim/update?claim=${props.claimId}`, { background: null })
      .then(() => {
        // console.log(response)
      }).catch(() => {
      // console.log(err)
    }).finally(() => {
      confirmLoading.value = false;
    })
  },
  beforeUpload = file => {
    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';

    if (!isJpgOrPngOrSvg) {
      message.error('You can only upload JPG, PNG or SVG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPngOrSvg && isLt2M;
  },
  getUrl = () => {
    return process.env.VUE_APP_BACKEND_URL + '/cabinet/claim/upload'
  },
  resetForm = () => {
    Object.assign(claim, new Claim({}));
    modalVisible.value = false;
    emit('hide-modal')
  },
  update = () => {
    confirmLoading.value = true;

    let model = toRaw(claim)

    return apiClient
      .put(`/claim/update?claim=${props.claimId}`, model)
      .then(response => {
        if (response) {
          resetForm()
        }
      }).catch(() => {
        // console.log(err)
      }).finally(() => {
        confirmLoading.value = false;
        modalVisible.value = false;
        emit('reload')
        emit('hide-modal')
      })
  };

apiClient.get(`/claim/view?claim=${props.claimId}`).then(response => {
  if (response) {
    imageUrl.value = response.data.data.claim.logo
    backgroundUrl.value = response.data.data.claim.background
    let model = new Claim(response.data.data.claim)
    Object.assign(claim, model)
  }
}).catch(() => {
  // console.log(err)
}).finally(() => {
  loading.value = false
})

onMounted(() => {
  nextTick(() => claimName.value?.focus())
  window.addEventListener('keydown', (e) => {
    if (e.key === 'Enter' && e.metaKey) {
      update()
    }
  });
})

</script>

<style scoped>
@import "./edit.css";
</style>
