export default function Brand(attrs) {
  this.name = attrs.name ? attrs.name : ''
  this.logo = attrs.logo ? attrs.logo : null
  this.background = attrs.background ? attrs.background : null
  this.tin = attrs.tin ? attrs.tin : null
  this.mfo = attrs.mfo ? attrs.mfo : null
  this.account_no = attrs.account_no ? attrs.account_no : null
  this.commission = attrs.commission ? attrs.commission : 2
  this.pos_type = attrs.pos_type ? attrs.pos_type : null
  this.wallet_type = attrs.wallet_type ? attrs.wallet_type : null
}
